import React from "react"
import Layout from "../components/layout";
import Title from "../components/title";
import { Helmet } from "react-helmet";
import { graphql } from 'gatsby'

import Klienti from "../images/klienti02.jpg"
import Klienti2 from "../images/klienti01.jpg"
import Klienti3 from "../images/klienti03.jpg" 

const Sluzby = ({data}) => (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Služby | Spark Performance</title> 
    </Helmet>
      <Layout>
      <Title title={'Služby'}  />
 
   
      <div className="relative bg-white py-16  ">
  <div className="grid lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
      
    <div className="relative py-8 sm:py-16 lg:py-0 order-2 lg:order-1">
      <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
        <div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72"></div>
        <svg className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12" width="404" height="392" fill="none" viewBox="0 0 404 392">
          <defs>
            <pattern id="02f20b47-fd69-4224-a62a-4c9de5c763f7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="392" fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
        </svg>
      </div>
       
      <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
        
        <div className="relative pt-80 pb-10 rounded-2xl shadow-xl overflow-hidden">
          <img className="absolute inset-0 h-full w-full object-cover" src={Klienti} alt="" />
          <div className="absolute inset-0 bg-red-500" style={{mixBlendMode: "multiply"}}></div>
          <div className="absolute inset-0 bg-gradient-to-t from-red-600 via-red-600 opacity-90"></div>
          <div className="relative px-8">
             
            <blockquote className="mt-8">
              <div className="relative text-lg font-medium text-white md:flex-grow">
                <svg className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-red-400" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
                  <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                </svg>
                <div className="relative">

                  {data?.scomos?.prvniSlogan?.content && 
                    <div dangerouslySetInnerHTML={{__html:  data?.scomos?.prvniSlogan?.content}}>
                    </div>
                  }
                </div>
                
              </div>

               
            </blockquote>
          </div>
        </div>
      </div>
      
    </div>

    <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 order-1 lg:order-2">
    
         {data?.scomos?.prvniText?.content && 
      <div className="  sm:pt-16 lg:pt-20 prose" dangerouslySetInnerHTML={{__html:  data?.scomos?.prvniText?.content}}>
            
      </div>
          }
 
    </div>
  </div> 
  <div className="mt-16 lg:mt-6 grid lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
    <div className="relative py-8 sm:py-16 lg:py-0 order-2  ">
      <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
        <div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72"></div>
        <svg className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12" width="404" height="392" fill="none" viewBox="0 0 404 392">
          <defs>
            <pattern id="02f20b47-fd69-4224-a62a-4c9de5c763f7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="392" fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
        </svg>
      </div>
      <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
        
        <div className="relative pt-80 pb-10 rounded-2xl shadow-xl overflow-hidden">
          <img className="absolute inset-0 h-full w-full object-cover" src={Klienti2} alt="" />
          <div className="absolute inset-0 bg-red-500" style={{mixBlendMode: "multiply"}}></div>
          <div className="absolute inset-0 bg-gradient-to-t from-red-600 via-red-600 opacity-90"></div>
          <div className="relative px-8">
             
            <blockquote className="mt-8">
              <div className="relative text-lg font-medium text-white md:flex-grow">
                <svg className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-red-400" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
                  <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                </svg>
                <div className="relative">

                  {data?.scomos?.druhySlogan?.content && 
                    <div dangerouslySetInnerHTML={{__html:  data?.scomos?.druhySlogan?.content}}>
                    </div>
                  }
                </div>
              </div>

               
            </blockquote>
          </div>
        </div>
      </div>
    </div>

    <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 order-1 ">
      
    {data?.scomos?.druhyText?.content && 
      <div className="  lg:pt-20 prose" dangerouslySetInnerHTML={{__html:  data?.scomos?.druhyText?.content}}>
            
      </div>
          }
        
 
    </div>
  </div>

  <div className="mt-16 lg:mt-6 grid lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
    <div className="relative py-8 sm:py-16 lg:py-0 order-2 lg:order-1">
      <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
        <div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72"></div>
        <svg className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12" width="404" height="392" fill="none" viewBox="0 0 404 392">
          <defs>
            <pattern id="02f20b47-fd69-4224-a62a-4c9de5c763f7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="392" fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
        </svg>
      </div>
      <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
        
        <div className="relative pt-80 pb-10 rounded-2xl shadow-xl overflow-hidden">
          <img className="absolute inset-0 h-full w-full object-cover" src={Klienti3} alt="" />
          <div className="absolute inset-0 bg-red-500" style={{mixBlendMode: "multiply"}}></div>
          <div className="absolute inset-0 bg-gradient-to-t from-red-600 via-red-600 opacity-90"></div>
          <div className="relative px-8">
             
            <blockquote className="mt-8">
              <div className="relative text-lg font-medium text-white md:flex-grow">
                <svg className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-red-400" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
                  <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                </svg>
                <div className="relative">
                {data?.scomos?.tretiSlogan?.content && 
                    <div dangerouslySetInnerHTML={{__html:  data?.scomos?.tretiSlogan?.content}}>
                    </div>
                  }
                  </div>
              </div>

               
            </blockquote>
          </div>
        </div>
      </div>
    </div>

    <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 order-1 lg:order-2">
      
    {data?.scomos?.tretiText?.content && 
      <div className=" sm:pt-16 lg:pt-20  prose" dangerouslySetInnerHTML={{__html:  data?.scomos?.tretiText?.content}}>
            
      </div>
          }

       
 
    </div>
  </div> 
</div>
    </Layout>
  </>
)
 
export default Sluzby


export const query = graphql`
  query Sluzby {
    scomos {
      prvniSlogan: pageItemFindOne(id:59) {
          content
      },
      prvniText: pageItemFindOne(id:60) {
          content
      },
      druhySlogan: pageItemFindOne(id:63) {
          content
      },
      druhyText: pageItemFindOne(id:62) {
          content
      },
      tretiSlogan: pageItemFindOne(id:65) {
          content
      },
      tretiText: pageItemFindOne(id:66) {
          content
      },
    }
  }
` 